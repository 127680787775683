<template>
    <v-form @submit.prevent="loginHandlerSubmit" ref="form">
        
        <v-text-field
            v-model="resetPassword.password"
            :append-icon="showPassword ? svg.visibility : svg.visibilityOff"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            :label="$t('resetPassword.newPassPlaceholder')"
            :loading="loadingForm"
            @click:append="showPassword = !showPassword"
            :color="colors.brandPrimColor"
            height="40"
            outlined
            autocomplete
            :rules='passwordRules'
  
        ></v-text-field>
        <v-text-field
            v-model="resetPassword.confirmedPassword"
            :append-icon="showPassword ? svg.visibility : svg.visibilityOff"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-2"
            :label="$t('resetPassword.confirmedNewPassPlaceholder')"
            :loading="loadingForm"
            @click:append="showPassword = !showPassword"
            :color="colors.brandPrimColor"
            height="40"
            outlined
            autocomplete
            :rules='confirmPasswordRules'
  
        ></v-text-field>

        <!-------------------------  FORM ERRORS  ------------------->
        <div v-if="errors" class="subtitle1 text-center text-capitalize red--text">
            <p v-for="(errorMessage, i) in errors" :key="i">{{errorMessage}}</p>
        </div>
        <!-------------------------  END FORM ERRORS ------------------->

        <div class="mt-5">
            <v-btn type="submit" large :loading="loadingForm" :color="colors.brandPrimColor" elevation="0" dark>
                {{$t('login.signinBtn')}}
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// MIXINS
import { reset } from '@/mixins/mixins';
import i18n from '@/plugins/i18n';


// SVG ICONS
import { mdiEyeOutline, mdiEyeOffOutline  } from '@mdi/js';

//VUEX
import { mapGetters } from 'vuex';
import axios from 'axios';


export default {
    mixins: [reset],
    data() {
        return {
            showPassword: false,
            resetPassword: {
                password: '',
                confirmedPassword: '',
            },
            passwordRules: [
                v => !!v || i18n.messages[this.locale].errorsList.login.errors.reqPass,
                v => (v && v.length >= 6) || i18n.t('errorsList.login.errors.passMoreThanNChars', {'n': '6'}),

            ],
            confirmPasswordRules: [
                v => !!v || i18n.messages[this.locale].errorsList.login.errors.reqPass,
                v => (v && v.length >= 6 ) || i18n.t('errorsList.login.errors.passMoreThanNChars', {'n': '6'}),
                v => (this.resetPassword.confirmedPassword == this.resetPassword.password) || i18n.t('resetPassword.passDontMatch'),

            ],
            svg: {
                visibility: mdiEyeOutline,
                visibilityOff: mdiEyeOffOutline,
            },
        }
    },
    methods: {
        loginHandlerSubmit() {
            if (this.$refs.form.validate()) {
                 this.$store.dispatch('RESET_PASSWORD', {
                    password: this.resetPassword.password,
                    token: this.$route.query.token,
                    locale: this.locale
                })
                .then(() => {
                    this.$router.push({name: 'login'});
                })
            }
        },
        
    },
    computed: {
        ...mapGetters(['colors', 'errors', 'loadingForm', 'theme', 'locale'])
    },
    beforeDestroy() {
        this.$store.dispatch('CLEAR_ERROR')
    },
    mounted() {
        
        
        

    },
}
</script>
<style lang="scss" scoped>

    fieldset {
        display: none;
    }
</style>
